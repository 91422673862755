import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesSat: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Pedimentos
            </h2>
            <h3>
                Manejo de pedimentos
            </h3>
            <p>
                Si tus productos son directamente importados por tu negocio, es ideal declarar los pedimentos en esta sección según la información proporcionada por la aduana. <br />
                <b>Los pedimentos se manejan automáticamente al momento de facturar, </b> de forma que si un pedido existe en 1 o más pedimentos, la catidad disponible en cada pedimento se va
                reduciendo conforme se facturan los pedidos (tanto factura manual, en Checkout o global). <br />
            </p>
            <p>
                <b>Los pedimentos se van descontando en orden de antigüedad registrada, si un producto existe en 2 pedimentos, hasta que se terminen las existencias del más viejo, se cambiará al mas nuevo</b>. <br />
                Cuando se terminen los pedimentos, podrás seguir facturando sin problema, solo las facturas no tendrán el numero de pedimento en ellas.
            </p>

            <ImageFooted explanation={`La vista inicial de Pedimentos, muestra en detalle los productos por Pedimento declarado y las existencias estimadas para cada producto según el pedimento original.`}>
                <IMG_Shopify file={'pedimentos'} />
            </ImageFooted>
            <h3>
                Agregar nuevo pedimento
            </h3>
            <ImageFooted explanation={`Crear pedimentos es tan sencillo como llenar una lista, buscas dentro de tus productos de Shopify por nombre y estableces la cantidad que contiene el pedimento.`}>
                <IMG_Shopify file={'pedimentosNew'} />
            </ImageFooted>
            <SectionNavigate previous={`/ecommerce/shopify/claves-sat`} next={`/ecommerce/shopify/mis-clientes`}/>
        </div>
    </Layout>
)

export default ShopifyClavesSat;